<template>
  <v-container
    fluid
    tag="section"
  >
    <!--  -->
    <v-row justify="center">
      <!--  -->
      <v-col
        cols="12"
        md="8"
      >
        <!--  -->
        <base-material-card>
          <!--  -->
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Edit Event
            </div>
          </template>
          <!--  -->
          <v-form>
            <!--  -->
            <v-container class="py-0">
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="event.data.id"
                    label="ID"
                    disabled
                    prepend-icon="mdi-account-check"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    v-model="event.data.title"
                    label="Judul"
                    prepend-icon="mdi-format-title"
                    clearable
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-textarea
                    v-model="event.data.post"
                    label="Post Artikel"
                    prepend-icon="mdi-format-title"
                    clearable
                    :rules="rules"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <!--  -->
              <!-- Tngl Mulai -->
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="event.data.start_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="event.data.start_date"
                        label="Tgl.Mulai"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="event.data.start_date"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(event.data.start_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                >
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="event.data.start_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="event.data.start_time"
                        label="Jam Mulai"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="menu1"
                      v-model="event.data.start_time"
                      full-width
                    >
                      <div>
                        <v-btn
                          text
                          @click="$refs.menu1.save(event.data.start_time)"
                        >
                          save
                        </v-btn>
                      </div>
                    </v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!--  -->
              <!-- Jam Mulai -->
              <!--  -->
              <!-- Tngl Selesai -->
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                >
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="event.data.end_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="event.data.end_date"
                        label="Tgl.Selesai"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="event.data.end_date"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(event.data.end_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                >
                  <v-menu
                    ref="jamSelesai"
                    v-model="jamSelesai"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="event.data.end_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="event.data.end_time"
                        label="Jam Selesai"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="jamSelesai"
                      v-model="event.data.end_time"
                      full-width
                    >
                      <div>
                        <v-btn
                          text
                          @click="$refs.jamSelesai.save(event.data.end_time)"
                        >
                          save
                        </v-btn>
                      </div>
                    </v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!--  -->
              <!-- Jam Selesai -->
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-select
                    v-model="event.data.polling_id"
                    label=" Pilih Polling"
                    :items="polling.data"
                    prepend-icon="mdi-certificate"
                    required
                    item-text="question"
                    item-value="id"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-img
                    v-if="event.data.image.image.small"
                    :src="event.data.image.image.small"
                    height="200"
                    width="200"
                    aspect-ratio="1.7"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <v-file-input
                    v-model="file1"
                    :state="Boolean(file1)"
                    :loading="upload_state"
                    :disabled="disable_state"
                    counter
                    show-size
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    placeholder="Edit Gambar Thumbnail"
                    @change="uploadImage"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    v-model="event.data.location"
                    label="Lokasi"
                    clearable
                    prepend-icon="mdi-format-title"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <!--  -->
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="getUpdate"
                  >
                    SUNTING
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    data () {
      return {
        menu: false,
        menu1: false,
        menu2: false,
        jamSelesai: false,
        polling: {
          data: [],
        },
        textFieldProps: {
          prependIcon: 'mdi-calendar',
        },
        timeProps: {
          useSeconds: false,
          ampmInTitle: true,
        },
        event: {
          meta: {},
          data: {
            name: '',
            post: '',
            status: 'publish',
            prefix: '',
            start_at: '',
            created_at: '2020-01-01 00:00:00',
            updated_at: '2020-01-01 00:00:00',
            end_at: '2020-01-01 00:00:00',
            location: '',
            image: {
              image: {},
            },
          },
        },
        status_items: ['publish', 'draft', 'review'],
        isEditing: false,
        startEvent: null,
        endEvent: null,
        rules: [
          value =>
            value.length > 3 ||
            'Form Harus Di isi dan harus Lebih dari 3 Karakter ',
        ],
        image_id: 4,
        upload_state: false,
        disable_state: false,
        file1: null,
        thumbnail_id: 4,
      }
    },
    mounted () {
      this.getEvent()
      this.getPolling()
    },
    methods: {
      upload (event) {
        this.upload_state = true
        console.log(event.target.files[0])
      },
      uploadImage (event) {
        if (this.file1 === null) {
          this.upload_state = false
          this.disable_state = false
          return 1
        }
        const URL = '/v1/media'
        this.upload_state = true
        const data = new FormData()
        data.append(
          'path',
          'image/news/community/' + this.$store.state.user.community_id,
        )
        data.append('file', this.file1)
        const config = {
          header: {
            'Content-Type': 'image/png',
          },
        }

        axios.post(URL, data, config).then(response => {
          if (response.data.meta.status) {
            this.upload_state = false
            this.disable_state = false
            this.image_id = response.data.data.id
            this.$toast.success('Berhasil Menunggah Gambar', {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          } else {
            this.upload_state = false
            this.disable_state = true
            this.$toast.error('Maksimal ukuran gambar 1MB', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          }
        })
      },
      setPolling (data) {
        this.polling = data
      },
      getPolling () {
        axios
          .get('/v1/polling?sort=-id&entities=user,community,options')
          .then(response => {
            this.setPolling(response.data)
          })
      },
      getUpdate () {
        axios
          .patch('/v1/post/event/' + this.$route.params.id, {
            title: this.event.data.title,
            post: this.event.data.post,
            start_date: this.event.data.start_date,
            start_time: this.event.data.start_time,
            end_date: this.event.data.end_date,
            end_time: this.event.data.end_time,
            polling_id: this.event.data.polling_id,
            thumbnail_id: this.image_id,
            image_id: this.image_id,
            location: this.event.data.location,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/event' })
              this.$toast.success('Berhasil Di Sunting', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data.meta.status)
            }
          // update
          })
      },
      setDate () {
        this.menu = false
      },
      setEvent (data) {
        // console.log(new Date())
        this.event = data
        this.startEvent = new Date(this.event.data.start_at)
        this.endEvent = new Date(this.event.data.end_at)
      },
      getEvent () {
        axios
          .get('/v1/post/event/' + this.$route.params.id)
          .then(response => {
            this.setEvent(response.data)
            this.image_id = response.data.data.image_id
          })
          .catch(e => {
            console.error(e)
          })
      },
    },
  }
</script>

<style></style>
